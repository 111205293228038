import { findInUserAgent } from "../utils/findInUserAgent";

export interface WindowsPlatformToken {
  [key: string]: OsType;
}

export enum OsType {
  Window98 = "Windows 98",
  WindowsMe = "Windows Me",
  NT = "Microsoft Windows NT 4.0",
  Windows2000 = "Windows 2000",
  Windows2000SP1 = "Windows 2000 SP1",
  WindowsXP = "Windows XP",
  Windows2003 = "Windows Server 2003; Windows XP x64 Edition",
  WindowsVista = "Windows Vista",
  Windows7 = "Windows7",
  Windows8 = "Windows 8",
  Windows81 = "Windows 8.1",
  Windows10 = "Windows 10",
  Windows101 = "Windows 10.1",
  Blackberry = "Blackberry",
  HpWebOs = "HP webOS",
  AmazonFireOs = "Amazon FireOS",
  Symbian = "Symbian",
  MacOs = "Mac OS",
  Linux = "Linux",
  WindowsPhone10 = "Windows Phone 10.0",
  WindowsPhone = "Windows Phone",
  FirefoxOS = "Firefox OS",
  Android = "Android",
  Ios = "iOS",
  other = "other",
  UNKNOWN = "unknown",
}

/**
 *
 *
 *
 *
 */

export function os(): OsType {
  /*                                                                            */

  if (!findInUserAgent) {
    return OsType.UNKNOWN;
  }

  const windowsPlatformToken: WindowsPlatformToken = {
    "Windows 98": OsType.Window98,
    "Windows 98; Win 9x 4.90 ": OsType.WindowsMe,
    "Windows NT 4.0": OsType.NT,
    "Windows NT 5.0": OsType.Windows2000,
    "Windows NT 5.01": OsType.Windows2000SP1,
    "Windows NT 5.1": OsType.WindowsXP,
    "Windows NT 5.2": OsType.Windows2003,
    "Windows NT 6.0": OsType.WindowsVista,
    "Windows NT 6.1": OsType.Windows7,
    "Windows NT 6.2": OsType.Windows8,
    "Windows NT 6.3": OsType.Windows81,
    "Windows NT 10.0": OsType.Windows10,
    "Windows NT 10.1": OsType.Windows101,
  };

  /*                                                                                    */

  /*                                              */
  const windows = Object.entries(windowsPlatformToken).filter(([key]) => {
    return findInUserAgent(key.toLowerCase());
  })[0];

  if (windows) {
    const [, value] = windows;
    return value;
  }

  /*           */
  const blackberryOS = ["blackberry", "bb10", "rim", "playbook"];
  if (blackberryOS.some(findInUserAgent)) {
    return OsType.Blackberry;
  }

  /*       */
  const webOs = ["webos", "hpwos"];
  if (webOs.some(findInUserAgent)) {
    return OsType.HpWebOs;
  }
  /*                                                                                    */
  const fireOS = ["silk", "kfot", "kftt", "kfjw", "kfsowi", "kfthw", "kfapw"];
  if (fireOS.some(findInUserAgent)) {
    return OsType.AmazonFireOs;
  }
  /*      */
  if (findInUserAgent("symbianos")) {
    return OsType.Symbian;
  }
  /*    */
  if (findInUserAgent("macintosh") && findInUserAgent("mac os x")) {
    return OsType.MacOs;
  }
  /*    */
  if (findInUserAgent("linux") && !findInUserAgent("android") && !findInUserAgent("webos")) {
    return OsType.Linux;
  }
  /*                 */
  if (findInUserAgent("windows phone 10.0")) {
    return OsType.WindowsPhone10;
  }
  /*            */
  if (findInUserAgent("windows phone")) {
    return OsType.WindowsPhone;
  }

  /*                                                                                 */
  const firefoxOS = ["(mobile;", "(tablet;"];
  if (firefoxOS.some(findInUserAgent)) {
    return OsType.FirefoxOS;
  }
  /*                                                            */
  if (findInUserAgent("android")) {
    return OsType.Android;
  }
  /*  */
  const ios = ["iphone", "ipad", "ipod"];
  if (ios.some(findInUserAgent)) {
    return OsType.Ios;
  }

  return OsType.other;
}
