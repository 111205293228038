import { deviceEvents, EVENTS } from "./events";

/**
 *
 *
 *
 *
 *
 *
 */
export function isTouchable(): boolean {
  const data = {
    ontouchstart: "ontouchstart" in window,
    /*                                    */
    documentTouch: !!(window.DocumentTouch && document instanceof window.DocumentTouch),
    maxTouchPoints: !!window.navigator.maxTouchPoints,
  };

  const isTouchableFlag = data.ontouchstart || data.documentTouch || data.maxTouchPoints;

  if (isTouchableFlag) {
    /*                                                                        */
    document.documentElement.classList.add("touchable");
    /*                   */
    deviceEvents.emitRetain(EVENTS.touchableDetected, data);
  } else {
    document.documentElement.classList.add("not-touchable");
  }

  return isTouchableFlag;
}
