import { o_global, o_util } from "@gr-common/body/namespaces";
import breakpointInstance, { type Breakpoints } from "./breakpoint.js";
import type { OrientationChangedCallback, OrientationType } from "../device/body.js";

const { submitEvent } = otto.tracking;
const { orientation } = o_global.device;

/**
 *
 */
function getCurrentData(): [OrientationType, Breakpoints | "unknown"] {
  return [orientation.getCurrentOrientation(), breakpointInstance.getCurrentBreakpoint()] as const;
}

/*                                                                */
let [currentOrientation, currentBreakpoint] = getCurrentData();

let breakpointTimer: number | undefined | NodeJS.Timeout;
let orientationTimer: number | undefined | NodeJS.Timeout;

/**
 *
 *
 *
 *
 *
 */
function sendTracking(orientationChanged: boolean, breakpointChanged: boolean): void {
  if (o_util.misc.isPreview(window.location.href)) {
    return;
  }

  const sendData = {
    ot_BreakpointChange: [breakpointChanged.toString()],
    ot_OrientationChange: [orientationChanged.toString()],
  };

  submitEvent(sendData);
}

function handleOrientationChange(): void {
  if (currentBreakpoint === breakpointInstance.getCurrentBreakpoint()) {
    sendTracking(true, false);
    currentOrientation = orientation.getCurrentOrientation();
    return;
  }

  clearTimeout(breakpointTimer);
  sendTracking(true, true);
  [currentOrientation, currentBreakpoint] = getCurrentData();
}

export const orientationChangeListener = function () {
  /*                                                                                                                 */
  /*                                                                        */
  /*                                                               */

  /*                                                                                         */
  orientationTimer = setTimeout(handleOrientationChange, 500);
} satisfies OrientationChangedCallback;

function handleBreakpointChange(): void {
  if (currentOrientation === orientation.getCurrentOrientation()) {
    /*                                          */
    sendTracking(false, true);
    currentBreakpoint = breakpointInstance.getCurrentBreakpoint();
    return;
  }

  clearTimeout(orientationTimer);
  sendTracking(true, true);
  [currentOrientation, currentBreakpoint] = getCurrentData();
}

export const breakpointChangeListener = function () {
  /*                                                                                         */
  breakpointTimer = setTimeout(handleBreakpointChange, 500);
} satisfies OrientationChangedCallback;

/*                                  */
orientation.onOrientationChanged(orientationChangeListener);
/*                                                          */
/*                                                                                      */
breakpointInstance.onBreakpointChanged(breakpointChangeListener);
