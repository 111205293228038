export const enum ColorScheme {
  LIGHT = "light",
  DARK = "dark",
}

export function preferredColorScheme(): ColorScheme {
  const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  return prefersDark ? ColorScheme.DARK : ColorScheme.LIGHT;
}

export const enum ContrastLevel {
  MORE = "more",
  LESS = "less",
  CUSTOM = "custom",
}

export function preferredContrast(): ContrastLevel | null {
  if (window.matchMedia("(prefers-contrast: more)").matches) {
    return ContrastLevel.MORE;
  }
  if (window.matchMedia("(prefers-contrast: less)").matches) {
    return ContrastLevel.LESS;
  }
  if (window.matchMedia("(prefers-contrast: custom)").matches) {
    return ContrastLevel.CUSTOM;
  }
  return null;
}
