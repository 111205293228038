import breakpointInstance from "./breakpoint.js";

/**
 *
 *
 *
 */
export function setDevSpecsCookie(): void {
  const bp = breakpointInstance.getCurrentBreakpoint();
  const { clientHeight, clientWidth } = document.documentElement;

  const cookieValue = `w=${clientWidth}&h=${clientHeight}&bp=${bp}`;
  const expireDate = new Date();

  expireDate.setTime(expireDate.getTime() + 10 * 365 * 24 * 60 * 60 * 1000);
  document.cookie = `devSpecs=${cookieValue};path=/;domain=.otto.de;expires=${expireDate.toUTCString()}`;
}

/*                           */
setDevSpecsCookie();

/*                                         */
breakpointInstance.onBreakpointChanged(setDevSpecsCookie);

/**
 *
 */
/*                                                                   */
