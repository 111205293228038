import { o_global } from "@gr-common/body/namespaces";
import type { ApmRum } from "@gr-exports/head/apm";
import { AsyncNamespace } from "../nexus/namespace/namespace.types";

declare global {
  interface NexusInterfaces {
    /**
 *
 *
 */
    debug: AsyncNamespace<{
      /*                      */
      sendLog: ApmRum["sendCustomError"];
    }>;
  }
}

const log = o_global.debug.logger("gr.legacy.debug");

/*                                                   */
otto.debug.assignNamespace(
  {
    sendLog: (namespace, data) => {
      log.warn("sendLog is deprecated, use apm.sendCustomError instead");
      return otto.apm.sendCustomError(namespace, data);
    },
  },
  { lock: import.meta.env.VITE_OTTO_IS_TESTING_BUNDLE !== "true" },
);
