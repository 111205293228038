import { findInUserAgent } from "../utils/findInUserAgent";

export enum Browser {
  SILK = "Silk",
  ICEWEASEL = "Iceweasel",
  NOKIA = "BrowserNG/NokiaBrowser",
  OTHER = "other",
  UNKNOWN = "unknown",
  EDGE = "Edge",
  CHROME = "Chrome",
  MOBILE_SAFARI = "Mobile Safari",
  SAFARI = "Safari",
  FIREFOX = "Firefox",
  OPERA = "Opera",
  OPERA_MOBILE = "Opera Mobile",
  OPERA_MINI = "Opera Mini",
  MSIE = "MSIE (not supported Version)",
  MSIE9 = "MSIE9",
  MSIE10 = "MSIE10",
  MSIE11 = "MSIE11",
}
/**
 *
 *
 *
 *
 */

export function browser(): Browser {
  if (!findInUserAgent) {
    return Browser.UNKNOWN;
  }

  /*                    */
  if (findInUserAgent("silk")) {
    return Browser.SILK;
  }
  /*        */
  if (findInUserAgent("iceweasel")) {
    return Browser.ICEWEASEL;
  }
  /*           */
  if (findInUserAgent("nokiabrowser") || findInUserAgent("browserng")) {
    return Browser.NOKIA;
  }
  /*                                */
  if (findInUserAgent("edg")) {
    return Browser.EDGE;
  }
  /*                   */
  if (
    (findInUserAgent("crios") || findInUserAgent("chrome")) &&
    !findInUserAgent("opera") &&
    !findInUserAgent("opr/")
  ) {
    return Browser.CHROME;
  }
  /*                                           */
  if (
    findInUserAgent("safari") &&
    findInUserAgent("mobile") &&
    !findInUserAgent("chrome") &&
    !findInUserAgent("crios") &&
    !findInUserAgent("silk")
  ) {
    return Browser.MOBILE_SAFARI;
  }
  /*     */
  if (
    findInUserAgent("safari") &&
    !findInUserAgent("chrome") &&
    !findInUserAgent("crios") &&
    !findInUserAgent("silk")
  ) {
    return Browser.SAFARI;
  }
  /*      */
  if (findInUserAgent("firefox") && !findInUserAgent("seamonkey")) {
    return Browser.FIREFOX;
  }

  /*            */
  /*                */
  if (findInUserAgent("msie 9")) {
    return Browser.MSIE9;
  }
  if (findInUserAgent("msie 10")) {
    return Browser.MSIE10;
  }
  if (
    findInUserAgent("msie 11") ||
    findInUserAgent("rv:11.0") ||
    findInUserAgent("compatible; MSIE 8.0; Windows NT 6.1; Trident/4.0; Tablet PC 2.0") ||
    findInUserAgent("compatible; MSIE 7.0; Windows NT 6.3; Trident/7.0; Touch")
  ) {
    return Browser.MSIE11;
  }

  if (findInUserAgent("msie")) {
    return Browser.MSIE;
  }
  /*         */
  if (findInUserAgent("opera mini")) {
    return Browser.OPERA_MINI;
  }
  /*    */
  if (findInUserAgent("opera") || findInUserAgent("presto") || findInUserAgent("opr/")) {
    /*           */
    if (findInUserAgent("mobi")) {
      return Browser.OPERA_MOBILE;
    }
    return Browser.OPERA;
  }
  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */

  return Browser.OTHER;
}
