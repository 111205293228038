import { GlobalEventLoader } from "../utils/eventLoader";
import { GlobalStorage } from "../utils/storage";
import { deviceEvents, HybridDetectedEventData } from "./events";
/*                                                         */
import type { DeviceEvents } from "./events";
import { executeCallback } from "../utils/callback";

type Timeout = NodeJS.Timeout;

const eventName = "mousemove";

const storageKey = "isHybrid";
/*                                                                                                   */
const minMovesForSuccess = 3;
/*                                                                                 */
const maxTimeForSuccess = 300;
/*                                                                          */
const mayAttemptsForSuccess = 8;

function markHybrid<T extends Hybrid>(instance: T, data: HybridDetectedEventData): T {
  instance.isHybrid = true;
  deviceEvents.emitRetain("assets.device.hybrid.detected", data);
  document.documentElement.classList.add("touchable-hybrid");
  return instance;
}

/*                                */
export class Hybrid {
  private storage = new GlobalStorage(() => window.sessionStorage);

  /*                                                                                     */
  private mouseMoveCounter = 0;

  /*                                                                      */
  private checkCounter = 0;

  /*                                                                                        */
  private timeoutIdArr: Timeout[] = [];

  /*                                               */
  isHybrid = false;

  /*                                           */
  get isDetected(): boolean {
    return this.isHybrid;
  }

  /*                       */
  private callbacks: (() => void)[] = [];

  /*                               */
  private document = document.documentElement;

  /*                                                       */
  detect(): this {
    if (!window.o_global.device.isTouchable) {
      /*                                              */
      return this;
    }

    if (this.storage.getItem(storageKey) === "true") {
      /*                                              */
      return markHybrid(this, { fromStorage: true });
    }

    if (!this.storage.getItem(storageKey)) {
      /*                                                                          */
      GlobalEventLoader.onAllJavascriptLoaded(200, () =>
        /*                                                                               */
        executeCallback(() => this.document.addEventListener(eventName, this.checkMouseMoves)),
      );
    }

    return this;
  }

  /**
 *
 *
 *
 *
 *
 */
  onHybridDetected(callback: () => void): void {
    if (this.isHybrid) {
      executeCallback(callback);
      return;
    }

    this.callbacks.push(callback);
  }

  /*                                                   */
  executeOrRegisterCallback = this.onHybridDetected;

  /*                                     */
  private checkMouseMoves = (): void => {
    this.mouseMoveCounter += 1;

    if (this.mouseMoveCounter > minMovesForSuccess) {
      this.cleanup();
      /*                     */
      markHybrid(this, { fromDetector: true });
      /*                                   */
      this.storage.setItem(storageKey, "true");
      /*                        */
      this.callbacks.forEach(executeCallback);
    }

    /*                                             */
    if (this.isHybrid) {
      /*                                */
      return;
    }

    /*                       */
    /*                                      */
    /*                                       */
    const timeoutId: Timeout = setTimeout(
      () => {
        /*                                                                 */
        this.mouseMoveCounter -= 1;
        this.checkCounter += 1;

        if (this.checkCounter > mayAttemptsForSuccess) {
          this.cleanup();
          this.storage.setItem(storageKey, "false");
        }
      },
      /*                                              */
      maxTimeForSuccess,
    );

    this.timeoutIdArr.push(timeoutId);
  };

  private cleanup(): void {
    this.timeoutIdArr.filter(Boolean).forEach(clearTimeout);
    this.document.removeEventListener(eventName, this.checkMouseMoves);
  }
}

/**
 *
 *
 */
export function hybrid(): Hybrid {
  return new Hybrid().detect();
}
