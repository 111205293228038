/**
 *
 */
export const screen = {
  /*                               */
  getWidth: (): number => window.innerWidth,
  /*                                */
  getHeight: (): number => window.screen.height,
  /*                             */
  getDevicePixelRatio: (): number => window.devicePixelRatio || 0,
};
