import { findInUserAgent } from "../utils/findInUserAgent";

export enum DeviceType {
  MOBILE = "mobile",
  DESKTOP = "desktop",
  TABLET = "tablet",
  UNKOWN = "unknown",
}

/**
 *
 *
 *
 *
 */

export function type(): DeviceType {
  if (!findInUserAgent) {
    return DeviceType.UNKOWN;
  }

  /*             */
  if (
    (findInUserAgent("mobile") && !findInUserAgent("ipad")) ||
    findInUserAgent("(mobile;") ||
    findInUserAgent("phone") ||
    (findInUserAgent("android") && findInUserAgent("mobile") && !findInUserAgent("ipad")) ||
    findInUserAgent("ipod") ||
    findInUserAgent("iphone") ||
    (findInUserAgent("windows") && findInUserAgent("phone")) ||
    (findInUserAgent("silk") && findInUserAgent("mobile")) ||
    findInUserAgent("symbianos") ||
    findInUserAgent("(mobile;")
  ) {
    return DeviceType.MOBILE;
  }

  /*             */
  if (
    findInUserAgent("playbook") ||
    findInUserAgent("tablet") ||
    findInUserAgent("(tablet;") ||
    findInUserAgent("touch") ||
    (findInUserAgent("android") && !findInUserAgent("mobile")) ||
    findInUserAgent("ipad") ||
    (findInUserAgent("windows") && !findInUserAgent("phone") && findInUserAgent("touch")) ||
    (findInUserAgent("silk") && !findInUserAgent("mobile")) ||
    findInUserAgent("(tablet;")
  ) {
    return DeviceType.TABLET;
  }

  /*         */
  if (findInUserAgent("blackberry") || findInUserAgent("bb10") || findInUserAgent("rim")) {
    return DeviceType.MOBILE;
  }

  return DeviceType.DESKTOP;
}
