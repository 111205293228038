import { executeCallback } from "../utils/callback";
import { EVENTS, deviceEvents } from "./events";
/*                                                         */
import type { DeviceEvents } from "./events";

export enum OrientationType {
  PORTRAIT = "portrait",
  LANDSCAPE = "landscape",
  UNKNOWN = "unknown",
}

/**
 *
 */
export type OrientationTypes = keyof typeof OrientationType;

/**
 *
 *
 */
export type OrientationChangedCallback = (activeOrientation: OrientationTypes) => unknown;

export class Orientation {
  private callbacks: OrientationChangedCallback[] = [];

  private orientation: {
    portrait: MediaQueryList;
    landscape: MediaQueryList;
  } = {
    portrait: window.matchMedia(`(orientation: ${OrientationType.PORTRAIT})`),
    landscape: window.matchMedia(`(orientation: ${OrientationType.LANDSCAPE})`),
  };

  constructor() {
    this.initializeOrientationListeners();
  }

  getCurrentOrientation(): OrientationType {
    if (this.isPortrait()) {
      return OrientationType.PORTRAIT;
    }

    if (this.isLandscape()) {
      return OrientationType.LANDSCAPE;
    }

    return OrientationType.UNKNOWN;
  }

  /**
 *
 *
 *
 *
 *
 */
  onOrientationChanged(callback: OrientationChangedCallback): this {
    this.callbacks.push(callback);
    return this;
  }

  /*                                                       */
  registerChangeListener = this.onOrientationChanged;

  private trigger(mql: MediaQueryListEvent): void {
    /*                                                       */
    /*                                                 */
    /*                                                    */
    /*                                                                              */

    if (mql.matches) {
      /*                                                         */
      const currentOrientation = this.getCurrentOrientation() as unknown as OrientationTypes;

      this.callbacks.forEach((cb) => {
        executeCallback(cb, currentOrientation);
      });

      deviceEvents.emit(EVENTS.orientationChanged, currentOrientation);
    }
  }

  /*                                  */
  private initializeOrientationListeners(): this {
    /*                                               */
    /*                                                                                  */
    /*                                                       */
    this.orientation.portrait.addListener((e) => this.trigger(e));

    this.orientation.landscape.addListener((e) => this.trigger(e));

    return this;
  }

  isPortrait(): boolean {
    return this.orientation.portrait.matches;
  }

  /**
 *
 *
 *
 *
 */
  isLandscape(): boolean {
    return this.orientation.landscape.matches;
  }
}

/**
 *
 *
 */
export function orientation(): Orientation {
  return new Orientation();
}
