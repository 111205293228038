import { o_util } from "@gr-common/body/namespaces";
const event = o_util.event ?? {};
const delegate = event.delegate;
const stop = event.stop;
const undelegate = event.undelegate;
const whichTransitionEndEvent = event.whichTransitionEndEvent;
export {
  delegate,
  event,
  stop,
  undelegate,
  whichTransitionEndEvent
};
